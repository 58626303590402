export const IMAGES_PATH = {
  logo: {
    logoBlue: "/assets/cricket/logo/tlc_group_logo.png",
    logo: "/assets/cricket/logo/logo.svg",
    logoWhite: "/assets/cricket/logo/cricket-logo.png",
    brandLogo: "/assets/cricket/logo/cricket-logo.png",
    brandMobileLogo: "/assets/cricket/logo/mobilelogo.png",
  },
  icons: {
    bag: "/assets/cricket/icons/bag.svg",
    user: "/assets/cricket/icons/user.svg",
    togglerDark: "/assets/cricket/icons/toggler.svg",
    dashboard: "/assets/cricket/icons/dashboard.svg",
    wellness: "/assets/cricket/icons/wellness.svg",
    dining: "/assets/cricket/icons/dining.svg",
    activites: "/assets/cricket/icons/activities.svg",
    entertainment: "/assets/cricket/icons/entertainment.svg",
    travel: "/assets/cricket/icons/travel.svg",
    learning: "/assets/cricket/icons/learning.svg",
  },
  slider: {
    slide1: "/assets/cricket/banners/slider1.png",
    slide2: "/assets/cricket/banners/slider2.png",
  },
  background: {
    loginBackground: "/assets/cricket/backgrounds/loginBackground.jpg",
    homeBackground: "/assets/cricket/backgrounds/home_banner.jpg",
    privacyBackground: "/assets/cricket/backgrounds/legal-bnr.png",
    termsBackground: "/assets/cricket/backgrounds/legal-bnr.png",
    cartBackground: "/assets/cricket/backgrounds/cart_banner.jpg",
    signupBackground: "/assets/cricket/backgrounds/signupBackground.png",
    loginNewBackground: "/assets/cricket/backgrounds/loginBackground.png",
    listing: "/assets/cricket/backgrounds/listing.jpg",
    checkout: "/assets/cricket/backgrounds/checkout.jpg",
  },
  pageImage: {
    checkoutSuccess: "/assets/cricket/images/checkout-success.png",
    characters: "/assets/cricket/images/Characters1.png",
    cricketText: "/assets/cricket/images/cricketText.png",
  },
};
