import {
  IAuthActionTypes,
  IAuthActionCreator,
  IAuthState,
  INewUserActionTypes,
} from "models/IAuthState";

const initialState: IAuthState = {
  isLoading: false,
  isSigningIn: false,
  isResetPasswordLoading: false,
  isUpdatePasswordLoading: false,
  user: null,
  role: null,
  isLoggedIn: false,
  code: "",
  isAdmin: {},
  isLoginModalOpen: false,
  twofaRequest: false,
  isSignupModalOpen: true,
  resendTwoFa: false,
};

const reducer = (
  state = initialState,
  { type, payload }: IAuthActionCreator
) => {
  switch (type) {
    case IAuthActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isSigningIn: true,
      };
    case IAuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isSigningIn: false,
        isLoginModalOpen: false,
        isLoggedIn: true,
      };
    case IAuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isSigningIn: false,
      };

    case IAuthActionTypes.HANDLE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: payload.open,
      };
    case IAuthActionTypes.HANDLE_TWO_FA:
      return {
        ...state,
        twofaRequest: payload,
      };

    case IAuthActionTypes.HANDLE_SIGNUP_MODAL:
      return {
        ...state,
        isSignupModalOpen: payload.open,
      };
    case IAuthActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordLoading: true,
      };

    case IAuthActionTypes.RESET_PASSWORD:
      return {
        ...state,
        isResetPasswordLoading: false,
      };
    case IAuthActionTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isUpdatePasswordLoading: true,
      };
    case IAuthActionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatePasswordLoading: false,
      };
    case IAuthActionTypes.LOGOUT:
      return { ...initialState };
    case IAuthActionTypes.SILENT_LOGIN:
      return {
        ...state,
        user: payload.user,
        role: payload.role,
        code: payload.code,
      };
    case IAuthActionTypes.TWOFA_REQUEST:
      return {
        ...state,
        isSigningIn: true,
        twofaRequest: true,
      };
    case IAuthActionTypes.TWOFA_SUCCESS:
      return {
        ...state,
        isSigningIn: false,
        twofaRequest: false,
      };
    case IAuthActionTypes.TWOFA_FAILURE:
      return {
        ...state,
        user: null,
        twofaRequest: false,
        isSigningIn: false,
      };
    case IAuthActionTypes.RESEND_TWOFA_REQUEST:
      return {
        ...state,
        resendTwoFa: payload.sent,
        isSigningIn: true,
        twofaRequest: true,
      };
    case IAuthActionTypes.RESEND_TWOFA_SUCSESS:
      return {
        ...state,
        twofaRequest: true,
        isSigningIn: true,
        resendTwoFa: true,
      };
    case IAuthActionTypes.RESEND_TWOFA_FAILURE:
      return {
        ...state,
        twofaRequest: true,
        isSigningIn: true,
        resendTwoFa: false,
      };

    default:
      return state;
  }
};

export default reducer;
