// Navbar.tsx
import React from "react";
import { IMAGES_PATH } from "configs/imagesPath";
import { PATH_NAME } from "configs/pathName";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { USER_ROLE } from "configs/userRole";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import authService from "services/authService";
import "./styles.scss";

type Anchor = "top" | "left" | "bottom" | "right";

const MainNavbar = ({ isLoggedIn, cartItems = [], user }: any) => {
  const role = authService.getRole();
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="text-end m-1">
        <IconButton
          onClick={toggleDrawer(anchor, true)}
          color="primary"
          aria-label="Close menu"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <List className="mobile-menu">
        <ListItem disablePadding>
          <ListItemButton
            className="mobileMenuItem"
            onClick={() => history.push(PATH_NAME.ROOT)}
            role="link" // Explicitly mark this as a link for accessibility
            aria-label="Go to Home page"
          >
            <ListItemIcon className="menu-iocn"></ListItemIcon>
            <ListItemText className="link-test">Home</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className="mobileMenuItem"
            onClick={() => history.push(PATH_NAME.OFFICIAL_RULES)}
            role="link" // Explicitly mark this as a link for accessibility
            aria-label="View Official Rules"
          >
            <ListItemIcon className="menu-iocn"></ListItemIcon>
            <ListItemText className="link-test">Official Rules</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            className="mobileMenuItem"
            onClick={() => history.push(PATH_NAME.FAQ)}
            role="link" // Explicitly mark this as a link for accessibility
            aria-label="Go to FAQ page"
          >
            <ListItemIcon className="menu-iocn"></ListItemIcon>
            <ListItemText className="link-test">FAQ</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      {role === USER_ROLE.ADMIN ? null : (
        <>
          <Navbar
            // sticky="top"
            className="bg-white border-bottom main-navbar d-none d-lg-block desktop-menu"
          >
            <Container>
              <Navbar.Brand
                href={PATH_NAME.ROOT}
                className="d-flex align-items-center"
              >
                <img
                  src={IMAGES_PATH.logo.brandLogo}
                  alt="Cricket brand logo"
                  className="img-fluid"
                  aria-label="Cricket brand logo" // Optional: If you need to describe the image in more detail
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <div className="d-flex ms-auto align-items-center items-menu">
                  <a
                    href={process.env.REACT_APP_BASE_URL} // Use a valid href to ensure the link works as expected
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default anchor behavior and handle via history.push
                      history.push(PATH_NAME.ROOT);
                    }}
                    aria-current={
                      location.pathname === PATH_NAME.ROOT ? "page" : undefined
                    }
                  >
                    Home
                  </a>
                  <a
                    href={PATH_NAME.OFFICIAL_RULES}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(PATH_NAME.OFFICIAL_RULES);
                    }}
                    aria-current={
                      location.pathname === PATH_NAME.OFFICIAL_RULES
                        ? "page"
                        : undefined
                    }
                    aria-label="View Official Rules"
                  >
                    Official Rules
                  </a>
                  <a
                    href={PATH_NAME.FAQ}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(PATH_NAME.FAQ);
                    }}
                    aria-current={
                      location.pathname === PATH_NAME.FAQ ? "page" : undefined
                    }
                    aria-label="Go to FAQ page"
                  >
                    FAQ
                  </a>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Navbar className="border-bottom shadow-sm main-navbar bg-white d-block d-lg-none">
            <Container>
              <Navbar.Brand
                href={PATH_NAME.ROOT}
                className="d-flex align-items-center"
              >
                <img
                  src={IMAGES_PATH.logo.brandMobileLogo}
                  className="img-fluid"
                  alt="Cricket brand logo"
                  aria-label="Cricket brand logo" // Optional: If you need to describe the image in more detail
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <div className="d-flex ms-auto align-items-center">
                  {(["right"] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                      <IconButton
                        onClick={toggleDrawer(anchor, true)}
                        size="medium"
                        aria-label="Mobile menu toggle"
                      >
                        <MenuIcon fontSize="large" />
                      </IconButton>
                      <Drawer
                        className="main-navbar mobile-menu"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  cartItems: state.auth.cartItems,
  user: state.auth.user?.user,
});

export default connect(mapStateToProps)(MainNavbar);
