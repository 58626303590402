import { Skeleton } from "@mui/material";

function SkeletonLoader() {
  return (
    <div className="p-3">
      <div>
        <Skeleton variant="rounded" width="100%" height={500} />
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <Skeleton variant="text" width="40%" height={100} />
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <Skeleton variant="text" width="20%" height={100} />
      </div>
      <div className="mt-5 d-flex justify-content-around">
        <Skeleton variant="rounded" width="10%" height={80} />
        <Skeleton variant="rounded" width="10%" height={80} />
        <Skeleton variant="rounded" width="10%" height={80} />
        <Skeleton variant="rounded" width="10%" height={80} />
        <Skeleton variant="rounded" width="10%" height={80} />
      </div>
    </div>
  );
}

export default SkeletonLoader;
