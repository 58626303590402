import axios from "axios";
import api from "./api";

function getAccessToken() {
  const accessToken = window.localStorage.getItem("accessToken");
  return accessToken;
}
export const API_ENDPOINT = process.env.REACT_APP_ENDPOINT_URL;

export const getCustomersList = async ({
  itemsPerPage,
  page,
  searchTerms,
}: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post("/user/get_listing", body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getPromotionalCodes = async ({
  itemsPerPage,
  page,
  searchTerms,
}: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post("user_code/get_listing_codes", body);
    return response;
  } catch (error) {
    return error;
  }
};
export const getRedemptionCodes = async ({
  itemsPerPage,
  page,
  searchTerms,
}: any) => {
  const body = { limit: itemsPerPage, skip: page, search: searchTerms };
  try {
    const response = await api.post("user_code/get_redemption_codes", body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomerById = async (id: any) => {
  try {
    const response = await api.get(`/customers/profile/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const checkout = async (
  priceId: any,
  stripeCustomerId: any,
  quantity: any,
  price: number
) => {
  try {
    const response = await api.post(`/payment/checkout`, {
      priceId,
      stripeCustomerId,
      quantity,
      price,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCustomer = async (data: any) => {
  try {
    const payload = { ...data };

    // Check if data.password is a valid non-empty string
    if (
      data.password &&
      typeof data.password === "string" &&
      data.password.trim() !== ""
    ) {
      payload.password = data.password; // Include password in the payload
    } else {
      // If data.password is not a valid string, remove it from the payload
      delete payload.password;
    }

    const response = await api.patch(`/customers/profile/${data.id}`, payload);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteCustomer = async (id: any) => {
  try {
    const response = await api.delete(`/user/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCustomerLists = async () => {
  try {
    const response = await api.post("/customers/get_all_customers", {});
    return response;
  } catch (error) {
    return error;
  }
};

export const createTemplate = async (data: any) => {
  try {
    const response = await api.post("/email-template", data);
    return response;
  } catch (error) {
    return error;
  }
};
export const getEmailTemplatesList = async ({
  itemsPerPage,
  page,
  searchKeywords,
}: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchKeywords,
  };
  try {
    const response = await api.post("/email-template/get_listing", body);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteTemplate = async (body: any) => {
  try {
    const response = await api.delete(`/email-template/${body}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getTemplateById = async (id: any) => {
  try {
    const response = await api.get(`/email-template/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const updateTemplate = async (body: any) => {
  try {
    const response = await api.patch(`/email-template/${body.id}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadRegistrationCodeUsingCsv = async (csvFile: any | null) => {
  const formData = new FormData();
  // Check if a CSV file is provided
  if (csvFile) {
    formData.append("file", csvFile);
  }

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axios.post(
      process.env.REACT_APP_ENDPOINT_URL + `/user_code/set_offer_codes`,
      formData,
      { headers }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadRedemptionCodeUsingCsv = async (csvFile: any | null) => {
  const formData = new FormData();
  // Check if a CSV file is provided
  if (csvFile) {
    // Assuming you want to upload a CSV file
    formData.append("file", csvFile);
  }

  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axios.post(
      process.env.REACT_APP_ENDPOINT_URL + `/user_code/set_redemption_codes`,
      formData,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getRedemptionCode = async ({ itemsPerPage, page, id }: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    reward_id: id,
  };

  try {
    return await api.post("/offers/get_listing_codes", body);
  } catch (error) {
    return error;
  }
};

export const deleteRedemptionCode = async (body: any) => {
  try {
    return await api.post("/offers/remove_offer_codes", body);
  } catch (error) {
    return error;
  }
};

export const deleteUnusedCode = async (offer_id: any) => {
  try {
    return await api.post("/offers/remove_unused_offer_codes", { offer_id });
  } catch (error) {
    return error;
  }
};

export const getSingleCode = async (id: any) => {
  try {
    return await api.get(`/offers/get_offer_code/${id}`);
  } catch (error) {
    return error;
  }
};

export const updateOfferCode = async (body: any) => {
  try {
    return await api.patch(`/offers/update_offer_code/${body.id}`, body);
  } catch (error) {
    return error;
  }
};

export const getTermAndConditions = async ({
  itemsPerPage,
  page,
  searchKeywords,
}: any) => {
  const body = {
    limit: itemsPerPage,
    skip: page,
    search: searchKeywords,
  };
  try {
    const response = await api.post("/pages/get_listing", body);
    return response;
  } catch (error) {
    return error;
  }
};

export const createPages = async (body: any) => {
  try {
    return await api.post(`/pages`, body);
  } catch (error) {
    return error;
  }
};

export const getPageContentByName = async (body: any) => {
  try {
    return await api.post(`/pages/get_page_by_name`, {
      page: body,
    });
  } catch (error) {
    return error;
  }
};

export const createTerms = async (body: any) => {
  try {
    return await api.post(`/terms`, body);
  } catch (error) {
    return error;
  }
};
export const deleteTerms = async (id: any) => {
  try {
    return await api.delete(`/pages/${id}`);
  } catch (error) {
    return error;
  }
};

export const getSingleTerms = async (id: any) => {
  try {
    return await api.get(`/pages/${id}`);
  } catch (error) {
    return error;
  }
};

export const updateTerms = async (body: any) => {
  try {
    return await api.patch(`/pages/${body.id}`, body);
  } catch (error) {
    return error;
  }
};

export const getFaqsListUser = async (data: any) => {
  try {
    return await api.post("faq/get_faq", data);
  } catch (error) {
    return error;
  }
};

export const deleteFaq = async (id: any) => {
  try {
    return await api.delete(`faq/${id}`);
  } catch (error) {
    return error;
  }
};

export const getSingleFaq = async (id: any) => {
  try {
    return await api.get(`faq/${id}`);
  } catch (error) {
    return error;
  }
};
export const updateFaq = async (body: any) => {
  try {
    return await api.patch(`faq/${body.id}`, body);
  } catch (error) {
    return error;
  }
};

export const getFaqsList = async (data: any) => {
  try {
    return await api.post("faq/get_faq", data);
  } catch (error) {
    return error;
  }
};

export const createFaq = async (data: any) => {
  try {
    return await api.post("faq", data);
  } catch (error) {
    return error;
  }
};
