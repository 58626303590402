import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { connect } from "react-redux";

function Loader({ isLoading }: any) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

const mapStateToProps = (state: any) => ({
  isLoading: state.app.isLoading,
});

export default connect(mapStateToProps)(Loader);
