import { Fragment, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PATH_NAME } from "configs/pathName";
import { IRoutes } from "../models/IRoutes";
import NavLayout, { EmptyNavLayout } from "layouts/NavLayouts/NavLayout";
import { USER_ROLE } from "configs/userRole";
import AdminAccess from "guards/AdminGuard/AdminAccess";
import RoleRoute from "./RoleRoute";
import HomeSkeleton from "layouts/SkeletonLoader/SkeletonLoader";
import AdminLayoutDecider from "layouts/AdminLayoutDecider/AdminLayoutDecider";

//  Users routes
const Home = lazy(() => import("pages/Home/Home"));
const CommingSoon = lazy(() => import("components/CommingSoon/CommingSoon"));
const Login = lazy(() => import("pages/Login/Login"));
const Faq = lazy(() => import("pages/Faq"));
const Logout = lazy(() => import("pages/Logout"));

//  dashboard routes
const Dashboard = lazy(() => import("pages/Dashboard"));
const customers = lazy(() => import("pages/Dashboard/Customers"));
const RedemptionCodes = lazy(() => import("pages/Dashboard/RedemptionCodes"));
const Confirmation = lazy(() => import("pages/Confirmation"));
const OfficialRules = lazy(() => import("pages/OfficialRules"));
const Faqs = lazy(() => import("pages/Dashboard/Faqs"));
const AddFaq = lazy(() => import("pages/Dashboard/Faqs/AddFaq"));
const UpdateFaq = lazy(() => import("pages/Dashboard/Faqs/UpdateFaq"));
const TermsAndCondition = lazy(
  () => import("pages/Dashboard/TermsAndCondition")
);
const AddTermsCondition = lazy(
  () => import("pages/Dashboard/TermsAndCondition/AddTermsCondition")
);
const UpdateTermsCondition = lazy(
  () => import("pages/Dashboard/TermsAndCondition/UpdateTermsCondition")
);

const routesConfig: IRoutes[] = [
  {
    path: PATH_NAME.ROOT,
    layout: process.env.REACT_APP_SHOW_HOME ? NavLayout : EmptyNavLayout,
    routes: process.env.REACT_APP_SHOW_HOME
      ? [
          {
            exact: true,
            path: PATH_NAME.ROOT,
            component: Home,
          },
          {
            exact: true,
            path: PATH_NAME.HOME,
            component: Home,
          },
          {
            exact: true,
            path: PATH_NAME.CONFIRMATION,
            component: Confirmation,
          },
          {
            exact: true,
            path: PATH_NAME.LOGIN,
            component: Login,
          },
          {
            exact: true,
            path: PATH_NAME.FAQ,
            component: Faq,
          },
          {
            exact: true,
            path: PATH_NAME.OFFICIAL_RULES,
            component: OfficialRules,
          },

          // Dashboard Routes start here
          {
            exact: true,
            path: PATH_NAME.DASHBOARD,
            component: Dashboard,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.LOGOUT,
            component: Logout,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.MANAGE_CUSTOMERS,
            component: customers,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.REDEMPTION_CODE,
            component: RedemptionCodes,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.FAQS,
            component: Faqs,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.FAQS_ADD,
            component: AddFaq,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.FAQS_UPDATE,
            component: UpdateFaq,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.TERMS,
            component: TermsAndCondition,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.ADD_TERMS,
            component: AddTermsCondition,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
          {
            exact: true,
            path: PATH_NAME.UPDATE_TERMS,
            component: UpdateTermsCondition,
            guard: AdminAccess,
            requireRoles: [USER_ROLE.ADMIN],
          },
        ]
      : [
          {
            path: "*",
            component: CommingSoon,
          },
        ],
  },
  {
    path: "*",
    routes: [
      {
        exact: true,
        path: "/",
        component: Home,
      },
      {
        component: () => <Redirect to={PATH_NAME.ROOT} />,
      },
    ],
  },
];

const renderRoutes = (routes: IRoutes[]) => {
  return (
    <>
      {routes ? (
        <Suspense fallback={<HomeSkeleton />}>
          <Switch>
            {routes.map((route: IRoutes, idx: number) => {
              const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const Component = route.component;
              const requireRoles = route.requireRoles || [];

              return (
                <Route
                  key={`routes-${idx}`}
                  path={route.path}
                  exact={route.exact}
                  render={(props: any) => (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <RoleRoute requireRoles={requireRoles}>
                            <Component {...props} />
                          </RoleRoute>
                        )}
                      </Layout>
                    </Guard>
                  )}
                />
              );
            })}
          </Switch>
        </Suspense>
      ) : null}
    </>
  );
};

function Routes() {
  return (
    <>
      <AdminLayoutDecider>{renderRoutes(routesConfig)}</AdminLayoutDecider>
    </>
  );
}

export default Routes;
