import { PATH_NAME } from "configs/pathName";
import { USER_ROLE } from "configs/userRole";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { handleLoginModal } from "redux/actions/auth.action";
import authService from "services/authService";

const AdminAccess = ({ children }: any) => {
  const dispatch = useDispatch();
  const isAuth = authService.getAccessToken();
  const role = authService.getRole();

  if (!isAuth) {
    dispatch(handleLoginModal(true));
    return <Redirect to={PATH_NAME.ROOT} />;
  }
  if (role === USER_ROLE.ADMIN) {
    return children;
  } else {
    return <Redirect to={PATH_NAME.ROOT} />;
  }
};

export default AdminAccess;
