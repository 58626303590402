import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { PATH_NAME } from "configs/pathName";
import { roleSelector } from "selectors/auth.selector";

// selectors

type IProps = {
  requireRoles: string[] | [];
};

const RoleRoute = ({ children, requireRoles = [] }: any) => {
  const history = useHistory();
  const role = useSelector(roleSelector);

  useEffect(() => {
    if (!role || requireRoles.length === 0) return;

    const checkRole = requireRoles.includes(role);
    if (!checkRole) {
      history.replace(PATH_NAME.ERROR_403);
    }
  }, [history, role, requireRoles]);

  return <>{children}</>;
};

export default RoleRoute;
