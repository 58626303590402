import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

// Components
import App from "./App";

// Styles
import "./index.css";

// Performance measurement
import reportWebVitals from "./reportWebVitals";

// Context
import { GlobalProvider } from "context/GlobalContext";

// Service initialization
import initRequest from "services/initRequest";

// Redux persist
import { PersistGate } from "redux-persist/integration/react";

// Material-UI Theme
import { ThemeProvider } from "@mui/material";
import { theme } from "Theme";
import { configureStore } from "redux/store";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
const container = document.getElementById("root");
const root = createRoot(container!); // Use createRoot(container!) if you use TypeScript
const { store, persistor } = configureStore(); // Create your Redux store
// Initialize any services or setup
initRequest(store);

// Render the app within the necessary providers
root.render(
  <GlobalProvider>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {/* <GoogleReCaptchaProvider
            reCaptchaKey="6LebdoUqAAAAALvQHOR-Jus1-s8MWrXoQ1W0u2p0"
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: "head", // optional, default to "head", can be "head" or "body",
              nonce: undefined, // optional, default undefined
            }}
            container={{
              // optional to render inside custom element
              parameters: {
                badge: "bottomleft", // optional, default undefined
                theme: "dark", // optional, default undefined
              },
            }}
          ></GoogleReCaptchaProvider> */}
          <App />
        </ThemeProvider>
      </Provider>
    </PersistGate>
  </GlobalProvider>
);

// Measure and report app performance
reportWebVitals();
