import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducers from "./rootReducers";

const migrations = {
  3: (state: any) => {
    // Migration function for version 1
    // Convert the old state structure to the new structure
    return {
      ...state, // Update the state structure
    };
  },
  // Add more migration functions for other versions as needed
};

const persistConfig = {
  key: "root",
  storage,
  version: 4, // Update this version when the state structure changes
  migrate: createMigrate(migrations, { debug: false }), // Define your migration functions
};

export const configureStore = (initialState = {}) => {
  const persistedReducer = persistReducer(persistConfig, rootReducers);

  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );

  const persistor = persistStore(store);
  return { store, persistor };
};
