import { USER_ROLE } from "configs/userRole";
import DashboardLayouts from "layouts/DashboardLayouts";
import React from "react";
import authService from "services/authService";

function AdminLayoutDecider({ children }: any) {
  let isAdmin = false;
  const isAuth = authService.getAccessToken();
  const role = authService.getRole() as any;
  if (role && isAuth) {
    if (role === USER_ROLE.ADMIN) {
      isAdmin = true;
    }
  }
  return isAdmin ? (
    <DashboardLayouts isAdmin={isAdmin}>{children}</DashboardLayouts>
  ) : (
    <>{children}</>
  );
}

export default AdminLayoutDecider;
