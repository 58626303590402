import Swal from "sweetalert2";
import "./AlertEmpty.scss";

type IAlertProps = {
  title?: any;
  message?: any;
  button?: any;
  url?: any;
  onClick?: () => void;
  showCloseButton?: boolean;
};

const AlertEmpty = ({
  title,
  message,
  button,
  url,
  onClick,
  showCloseButton = true,
}: IAlertProps) => {
  Swal.fire({
    title: title,
    html: message,
    customClass: {
      popup: "popup-class",
      cancelButton: "cancel-button-class",
      htmlContainer: "html-class",
      title: "title-class",
      confirmButton: "confirm-button-class",
      closeButton: "close-button-class",
    },
    showCloseButton: showCloseButton,
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonText: button,
    cancelButtonText: "cancel",
    cancelButtonColor: "#D20A0A",
  }).then((result) => {
    if (result.isConfirmed) {
      if (onClick) {
        onClick(); // Call the passed onClick function
      }

      if (url) {
        window.location.href = `${window.location.origin}${url}`;
      }
    }
    // if (result.isConfirmed && url) {
    //   window.location.href = `${window.location.origin}${url}`;
    // }
  });
};

export default AlertEmpty;
