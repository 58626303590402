export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const addTitleToSVG = (svgString: any, altText: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, "image/svg+xml");
  const svgElement = doc.querySelector("svg");

  // Ensure the SVG element has a title tag for accessibility
  if (svgElement) {
    // Set aria-label and role attributes
    svgElement.setAttribute("aria-label", altText);
    svgElement.setAttribute("role", "img");

    // Add a <title> element if it doesn't already exist
    let titleElement = svgElement.querySelector("title");
    if (!titleElement) {
      titleElement = doc.createElement("title");
      titleElement.textContent = altText;
      svgElement.insertBefore(titleElement, svgElement.firstChild);
    } else {
      // Update existing title element
      titleElement.textContent = altText;
    }

    // Serialize the modified SVG back to a string
    return new XMLSerializer().serializeToString(doc);
  }

  return svgString; // Return original SVG if parsing failed
};

export const addTargetBlank = (content: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const allATags = doc.querySelectorAll("a");
  allATags.forEach((links) => {
    links.setAttribute("target", "_blank");
  });
  return doc.body.innerHTML; // Return the updated content
};
