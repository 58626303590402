import React from "react";

export const IAppActionTypes = {
  LOGOUT: "APP/LOGOUT",
  SET_LOADING: "APP/SET_LOADING",
  SET_DIALOG: "APP/SET_DIALOG",
  ENQUEUE_SNACKBAR: "APP/ENQUEUE_SNACKBAR",
  REMOVE_SNACKBAR: "APP/REMOVE_SNACKBAR",
  SET_EVENTSLIST: "APP/SET_EVENTSLIST",
  SET_PRODUCTLIST: "APP/SET_PRODUCTLIST",
  SET_COUPONLIST: "APP/SET_COUPONLIST",
  SET_POPUPLIST: "APP/SET_POPUPLIST",
  SET_ACTIVEPOPUP: "APP/SET_ACTIVEPOPUP",
  SET_SUBSCRITPION_TYPE: "APP/SET_SUBSCRITPION_TYPE",

  SET_REWARDS_LIST_REQUEST: "APP/SET_REWARDS_LIST_REQUEST",
  SET_REWARDS_LIST_SUCCESS: "APP/SET_REWARDS_LIST_SUCCESS",
  SET_REWARDS_LIST_FAILURE: "APP/SET_REWARDS_LIST_FAILURE",
  // For Reward Categories
  SET_REWARD_CATEGORIES_REQUEST: "APP/SET_REWARD_CATEGORIES_REQUEST",
  SET_REWARD_CATEGORIES_SUCCESS: "APP/SET_REWARD_CATEGORIES_SUCCESS",
  SET_REWARD_CATEGORIES_FAILURE: "APP/SET_REWARD_CATEGORIES_FAILURE",

  // For Redeem Rewards
  SET_REDEEMED_REWARDS_REQUEST: "APP/REDEEM_REWARDS_REQUEST",
  SET_REDEEMED_REWARDS_SUCCESS: "APP/REDEEM_REWARDS_SUCCESS",
  SET_REDEEMED_REWARDS_FAILURE: "APP/REDEEM_REWARDS_FAILURE",
  SET_ACTIVE_CUSTOMER_IN_REWARDS: "APP/SET_ACTIVE_CUSTOMER_IN_REWARDS",

  // For Redeem Rewards
  SET_LANGUAGE_REQUEST: "APP/SET_LANGUAGE_REQUEST",
  SET_LANGUAGE_SUCCESS: "APP/SET_LANGUAGE_SUCCESS",
  SET_LANGUAGE_FAILURE: "APP/SET_LANGUAGE_FAILURE",
};

type IDialog = {
  type: string;
  isShow: boolean;
  content?: React.ReactNode | string;
};

export type ITierType = {
  id: string;
  priceId: string;
  title: string;
  amount: string;
  desc1: string;
  desc2: string;
};

export type IAppState = {
  isLoading: boolean;
};

export type IAppActionCreator = {
  type: string;
  payload: any;
};

export type INotifer = {
  key: number | string;
  message?: string | React.ReactNode;
  variant?: "success" | "error" | "warning" | "info";
};
