import { AxiosInstance } from "axios";
import { axiosInstance, IConfig } from "./initRequest";

class HttpRequest {
  api: AxiosInstance;

  constructor() {
    this.api = axiosInstance;
  }

  async get(url: string, config?: IConfig) {
    return this.api.get(url, config);
  }

  async postReq(url: string, body?: any, config?: IConfig) {
    return this.api.post(url, body, config);
  }

  async deleteReq(url: string, config?: IConfig) {
    return this.api.delete(url, config);
  }

  async patchReq(url: string, body?: any, config?: IConfig) {
    return this.api.patch(url, body, config); // Use the patch method of Axios
  }

  async getReq(url: string, config?: IConfig) {
    return this.api.get(url, config);
  }
}

const httpRequest = new HttpRequest();

export default httpRequest;
