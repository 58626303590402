export enum IAuthActionTypes {
  LOGIN_REQUEST = "AUTH/LOGIN_REQUEST",
  LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "AUTH/LOGIN_FAILURE",

  LOGIN_REQUEST_BY_EMAIL = "AUTH/LOGIN_REQUEST_BY_EMAIL",
  LOGIN_SUCCESS_BY_EMAIL = "AUTH/LOGIN_SUCCESS_BY_EMAIL",
  LOGIN_FAILURE_BY_EMAIL = "AUTH/LOGIN_FAILURE_BY_EMAIL",

  LOGIN_CUSTOMER_REQUEST = "AUTH/LOGIN_CUSTOMER_REQUEST",
  LOGIN_CUSTOMER_SUCCESS = "AUTH/LOGIN_CUSTOMER_SUCCESS",
  LOGIN_CUSTOMER_FAILURE = "AUTH/LOGIN_CUSTOMER_FAILURE",

  TWOFA_REQUEST = "AUTH/LOGIN_2FA",
  TWOFA_SUCCESS = "AUTH/TWOFA_SUCCESS",
  TWOFA_FAILURE = "AUTH/TWOFA_FAILURE",
  HANDLE_TWO_FA = "AUTH/HANDLE_TWO_FA",

  OPEN_SIGNUP_MODAL = "AUTH/OPEN_SIGNUP_MODAL_SUCCESS",
  HANDLE_LOGIN_MODAL = "AUTH/HANDLE_LOGIN_MODAL",
  HANDLE_SIGNUP_MODAL = "AUTH/HANDLE_SIGNUP_MODAL",
  RESEND_TWOFA_REQUEST = "AUTH/RESEND_TWOFA_REQUEST",
  RESEND_TWOFA_SUCSESS = "AUTH/RESEND_TWOFA_SUCSESS",
  RESEND_TWOFA_FAILURE = "AUTH/RESEND_TWOFA_FAILURE",
  RESET_PASSWORD_REQUEST = "AUTH/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD = "AUTH/RESET_PASSWORD",
  UPDATE_PASSWORD_REQUEST = "AUTH/UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD = "AUTH/UPDATE_PASSWORD",
  SILENT_LOGIN = "AUTH/SILENT_LOGIN",
  LOGOUT = "AUTH/LOGOUT",
  REGISTER = "AUTH/REGISTER",
  GET_SUBSCRITPIONS = "AUTH/GET_SUBSCRITPIONS",
  SET_REQUEST_ADD_TO_CART = "APP/REQUEST_ADD_TO_CART",
  SET_REQUEST_ADD_TO_CART_SUCCESS = "APP/REQUEST_ADD_TO_CART_SUCCESS",
  SET_REQUEST_ADD_TO_CART_SUCCESS_SILENT = "APP/REQUEST_ADD_TO_CART_SUCCESS_SILENT",
  SET_REQUEST_ADD_TO_CART_FAILURE = "APP/REQUEST_ADD_TO_CART_FAILURE",

  SET_REQUEST_REMOVE_FROM_CART = "APP/REQUEST_REMOVE_FROM_CART",
  SET_REQUEST_REMOVE_FROM_CART_SUCCESS = "APP/REQUEST_REMOVE_FROM_CART_SUCCESS",
  SET_REQUEST_REMOVE_FROM_CART_FAILURE = "APP/REQUEST_REMOVE_FROM_CART_FAILURE",

  SET_REQUEST_CLEAR_CART = "APP/REQUEST_CLEAR_CART",
  SET_REQUEST_CLEAR_CART_SUCCESS = "APP/REQUEST_CLEAR_CART_SUCCESS",
  SET_REQUEST_CLEAR_CART_FAILURE = "APP/REQUEST_CLEAR_CART_FAILURE",

  SET_CART_OPEN = "APP/SET_CART_OPEN",
  SET_CART_CLOSE = "APP/SET_CART_CLOSE",
  TO_TEST = "APP/TO_TEST",

  SET_REQUEST_CHECKOUT = "APP/SET_REQUEST_CHECKOUT",
  SET_REQUEST_CHECKOUT_SUCCESS = "APP/SET_REQUEST_CHECKOUT_SUCCESS",
  SET_REQUEST_CHECKOUT_FAILURE = "APP/SET_REQUEST_CHECKOUT_FAILURE",

  SET_REQUEST_PROFILE_UPDATE = "APP/SET_REQUEST_PROFILE_UPDATE",
  SET_REQUEST_PROFILE_UPDATE_SUCCESS = "APP/SET_REQUEST_PROFILE_UPDATE_SUCCESS",
  SET_REQUEST_PROFILE_UPDATE_FAILURE = "APP/SET_REQUEST_PROFILE_UPDATE_FAILURE",
}

export enum INewUserActionTypes {
  CREATE_USER_REQUEST = "AUTH/CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS = "AUTH/CREATE_USER",
  CREATE_USER_FAILURE = "AUTH/CREATE_USER_FAILURE",
  GET_PROFILE_DETAILS_REQUEST = "AUTH/GET_PROFILE_DETAILS_REQUEST",
  GET_PROFILE_DETAILS_SUCCESS = "AUTH/GET_PROFILE_DETAILS_SUCCESS",
  GET_PROFILE_DETAILS_FAILURE = "AUTH/GET_PROFILE_DETAILS_FAILURE",
  HANDLE_NEXT_ACTIVE_STEP = "AUTH/HANDLE_NEXT_ACTIVE_STEP",
  HANDLE_PREV_ACTIVE_STEP = "AUTH/HANDLE_PREV_ACTIVE_STEP",
  UPDATE_USER_CONVERT = "AUTH/UPDATE_USER_CONVERT",
  SET_USER_PROFILE = "AUTH/SET_USER_PROFILE",
  SET_USER_PROFILE_REQUEST = "AUTH/SET_USER_PROFILE_REQUEST",
  SET_USER_PROFILE_SUCCESS = "AUTH/SET_USER_PROFILE_SUCCESS",
  SET_USER_PROFILE_FAILURE = "AUTH/SET_USER_PROFILE_FAILURE",
}

export type IAuthState = {
  isLoading: boolean;
  user: any;
  code: string;
  isSigningIn: boolean;
  isResetPasswordLoading: boolean;
  isUpdatePasswordLoading: boolean;
  role: string | null;
  isAdmin: any;
  isLoggedIn: boolean;
  isLoginModalOpen: boolean;
  isSignupModalOpen: boolean;
  twofaRequest: boolean;
  resendTwoFa: boolean; // Added because it's used in the reducer
};

export type IAuthActionCreator = {
  type: string;
  payload: any;
};
